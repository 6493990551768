/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:27443fef-b5c0-4a25-a3d6-63a9e8281407",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_R83dlZn76",
    "aws_user_pools_web_client_id": "6jtd839rngqbkni87dnopnh2cr",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api70d0a809",
            "endpoint": "https://g4lfg9oy6d.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_mobile_analytics_app_id": "baa3acff4e064658a40d29f0041cf014",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
