import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import AFHeaderLinks from "components/Header/AFHeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/reinvent_bg.jpg";

import { Analytics, API } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

const queryParametersString = new URLSearchParams(window.location.search);
const queryParametersObject = Object.fromEntries(queryParametersString.entries())
const cardTag = "tag" in queryParametersObject ? queryParametersObject["tag"] : "";

Analytics.autoTrack('session', {
    enable: true,
    attributes: () => {
        var attrs = {
            _url: window.location.href
        };
        Object.assign(attrs, queryParametersObject)
        return attrs;
    }
});

const apiName = 'api70d0a809';
const path = '/cards';
const myInit = {
    queryStringParameters: {},
};


if (cardTag !== "") {
    myInit.queryStringParameters["tag"] = cardTag;
}

const useStyles = makeStyles(styles);

const AuthStateApp = (props) => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    const classes = useStyles();
    const { ...rest } = props;
    const hiddenStyle = { display: "none" }

    var cardState = "question"
    var cardData = {}

    Analytics.autoTrack('event', {
        enable: true,
        attributes: () => {
            if ('id' in cardData) {
                return {
                    cardId: cardData.id
                };
            } else {
                return {};
            }

        }
    });

    function rotateCard(byUser = true) {
        if (cardState === "question") {
            document.querySelector("#question").style.display = "none";
            document.querySelector("#answer").style.display = "flex";
            cardState = "answer"
        } else {
            document.querySelector("#question").style.display = "flex";
            document.querySelector("#answer").style.display = "none";
            cardState = "question"
        }
    }

    function nextCard(confidence) {
        //TODO
        rotateCard(false);
        populateCard();
    }

    function populateCard() {
        API.get(apiName, path, myInit)
            .then(function (response) {
                cardData = response
                console.log(response);

                document.querySelector("#questionText").textContent = cardData.question
                document.querySelector("#answerText").textContent = cardData.answer
                document.querySelector("#answerReferance").href = cardData.referance
                document.querySelector("#tags").innerHTML = ""

                for (var i = 0; i < cardData.tags.length; i++) {
                    document.querySelector("#tags").innerHTML += `<a href="?tag=${cardData.tags[i]}">#${cardData.tags[i]}</a>&nbsp&nbsp`;
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                alert("Ooops, unable to fetch a card. Please try to refresh later...")
            });
    }

    useEffect(() => {
        if (authState === AuthState.SignedIn && user) {
            populateCard();
        }
    });

    return authState === AuthState.SignedIn && user ? (
        <div>
            <Header
                absolute
                color="transparent"
                brand={"AWS Flashcards" + (cardTag !== "" ? " #" + cardTag : "")}
                rightLinks={<AFHeaderLinks />}
                href="/"
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card
                                id="question"
                            >
                                <form className={classes.form}>
                                    <CardHeader color="primary" className={classes.cardHeader}>
                                        <h4>Question</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <h3 id="questionText">Here be question...</h3>
                                    </CardBody>
                                    <GridItem id="tags" xs={12} sm={12} md={12}>

                                    </GridItem>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button
                                            justIcon
                                            color="transparent"
                                            data-amplify-analytics-on='click'
                                            data-amplify-analytics-name='rotateCard'
                                            onClick={rotateCard}
                                        >
                                            <i className="fas fa-sync-alt" />
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                            <Card
                                id="answer"
                                style={hiddenStyle}
                            >
                                <form className={classes.form}>
                                    <CardHeader color="success" className={classes.cardHeader}>
                                        <h4>Answer</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <h3 id="answerText">Here be answer...</h3>
                                        <a id="answerReferance" href="." target="_blank"
                                            data-amplify-analytics-on='click'
                                            data-amplify-analytics-name='referanceClicked'>Referance</a>
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className={classes.title}>
                                                    <h3>
                                                        <small>Rate your confidence</small>
                                                    </h3>
                                                </div>
                                                <Button color="danger"
                                                    data-amplify-analytics-on='click'
                                                    data-amplify-analytics-name='nextCard'
                                                    data-amplify-analytics-attrs='confidence:1'
                                                    onClick={() => nextCard(1)}>Less</Button>
                                                <Button color="warning"
                                                    data-amplify-analytics-on='click'
                                                    data-amplify-analytics-name='nextCard'
                                                    data-amplify-analytics-attrs='confidence:2'
                                                    onClick={() => nextCard(2)}>Meh</Button>
                                                <Button color="success"
                                                    data-amplify-analytics-on='click'
                                                    data-amplify-analytics-name='nextCard'
                                                    data-amplify-analytics-attrs='confidence:3'
                                                    onClick={() => nextCard(3)}>Good</Button>
                                            </GridItem>
                                        </GridContainer>
                                    </CardFooter>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button
                                            justIcon
                                            color="transparent"
                                            data-amplify-analytics-on='click'
                                            data-amplify-analytics-name='rotateCard'
                                            onClick={rotateCard}
                                        >
                                            <i className="fas fa-sync-alt" />
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    ) : (
        <AmplifyAuthenticator usernameAlias="email">
            <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                    {
                        type: "email",
                        required: true,
                    },
                    {
                        type: "password",
                        required: true,
                    },
                ]}
            />
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
        </AmplifyAuthenticator>
    );
}

export default AuthStateApp;