/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { AmplifySignOut } from '@aws-amplify/ui-react';

const useStyles = makeStyles(styles);

export default function AFHeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <AmplifySignOut />
      </ListItem>
    </List>
  );
}
